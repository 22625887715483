import * as serviceWorker from "./utils/serviceWorker";
import React from "react";
import ReactDOM from "react-dom";
import Loadable from "react-loadable";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import packageJSON from "../package.json";

import LoadingComponent from "./components/LoadingComponent";
//import App from './App'

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn:
      "https://4773808a25504b3f924368efaee1847f@o423531.ingest.sentry.io/5353926",
    release: "team8s@" + packageJSON.version,
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0
  });
}

const Loading = () => <LoadingComponent />;

export const AppAsync = Loadable({
  loader: () => import("./App"),
  loading: Loading
});

const start = () => {
  ReactDOM.hydrate(
    <Sentry.ErrorBoundary fallback={"An error has occurred"}>
      <AppAsync />
    </Sentry.ErrorBoundary>,
    document.getElementById("root")
  );

  if (window.AdMob) {
    // window.AdMob.prepareInterstitial({
    //   adId: "ca-app-pub-2803667187798436/3299329339",
    //   autoShow: false
    // });
    // window.AdMob && window.AdMob.showInterstitial();
  }
};

if (!window.cordova) {
  start();
  serviceWorker.register();
} else {
  /*
   * Licensed to the Apache Software Foundation (ASF) under one
   * or more contributor license agreements.  See the NOTICE file
   * distributed with this work for additional information
   * regarding copyright ownership.  The ASF licenses this file
   * to you under the Apache License, Version 2.0 (the
   * "License"); you may not use this file except in compliance
   * with the License.  You may obtain a copy of the License at
   *
   * http://www.apache.org/licenses/LICENSE-2.0
   *
   * Unless required by applicable law or agreed to in writing,
   * software distributed under the License is distributed on an
   * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
   * KIND, either express or implied.  See the License for the
   * specific language governing permissions and limitations
   * under the License.
   */
  const app = {
    // Application Constructor
    initialize: function() {
      document.addEventListener(
        "deviceready",
        this.onDeviceReady.bind(this),
        false
      );
    },

    // deviceready Event Handler
    //
    // Bind any cordova events here. Common events are:
    // 'pause', 'resume', etc.
    onDeviceReady: function() {
      start();
      // eslint-disable-next-line no-console
      console.log("DEVICE READY");
    }
  };

  app.initialize();
}
